import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Typography, message } from 'antd'
import { UserOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons'
import { AuthModel } from '../../models/AuthModel'
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';

const { Title } = Typography

const Forgot = () => {
    const [progress, setProgress] = useState(false);
    const [email, setEmail] = useState(null);
    const [code, setCode] = useState(null);

    const onSubmitEmail = (v) => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        for (var i = 0; i < 7; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        setCode(text);

        message.loading('Verifying', 0)
        AuthModel.forgot(v.email, text).then(res => {
            if (res.status === 200) {
                setEmail(v.email)
                setProgress(true)
                message.destroy()
                message.success('Please check your email', 3)
            } else {
                message.destroy()
                message.error('Email not found !!', 3)
            }
        })
    }


    const onSubmitPassword = (v) => {
        message.loading('authenticating', 0)
        if (v.code === code) {
            if (v.password === v.confirm_password) {
                AuthModel.changePassword(email, v.password).then(res => {
                    message.destroy()
                    message.success(res.message, 2)
                    setTimeout(() => {
                        window.location.href = "/"
                    }, 2000);
                })
            } else {
                message.destroy()
                message.error('Confirm Password is not match with Password !!', 3)
            }
        } else {
            message.destroy()
            message.error('Verification code is wrong !!', 3)
        }
    }

    // Renderer callback with condition
    const renderer = (props) => {
        if (props.completed) {
            // Render a completed state
            window.location.href = "/login"
        } else {
            // Render a countdown
            return <span>{props.formatted.hours}:{props.formatted.minutes}:{props.formatted.seconds}</span>;
        }
    };

    return (
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
            <Col span={18}>
                <Card style={{ minHeight: 450, borderRadius: 10 }} className="box-shadow">
                    <Row type="flex" style={{ height: 450, marginBottom: 20 }} align="middle">
                        <Col xs={24} sm={8}>
                            <Card style={{ borderRadius: 10 }}>
                                <div className="full-width text-center" style={{ marginBottom: 30 }}>
                                    <img alt="hris-logo" src={process.env.PUBLIC_URL + '/logo-vertical.png'} style={{ width: 130 }} />
                                </div>
                                <div className="full-width" style={{ marginBottom: 10 }}>
                                    <Title level={5}>Reset Password</Title>
                                </div>
                                {
                                    progress === false ?
                                        <Form
                                            name="normal_login"
                                            className="login-form"
                                            size="large"
                                            initialValues={{ remember: true }}
                                            onFinish={onSubmitEmail}
                                        >
                                            <Form.Item name="email" rules={[{ type: 'email', required: true, message: 'Please input your email!' }]}>
                                                <Input prefix={<UserOutlined />} placeholder="Email" />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" block> Submit </Button>
                                            </Form.Item>
                                            <Form.Item style={{ marginTop: 0 }}>
                                                <Link to={"/login"}>
                                                    <span style={{ float: 'right' }}>
                                                        Back To Login
                                                    </span>
                                                </Link>
                                            </Form.Item>
                                        </Form> :
                                        <div>
                                            <Row justify="center" style={{ marginBottom: 20 }}>
                                                <Title level={4}><Countdown date={Date.now() + 300000} renderer={renderer} /></Title>
                                            </Row>
                                            <Form
                                                name="normal_login"
                                                className="login-form"
                                                size="large"
                                                initialValues={{ remember: true }}
                                                onFinish={onSubmitPassword}
                                            >
                                                <Form.Item name="code" rules={[{ required: true, message: 'Please input code!' }]}>
                                                    <Input prefix={<KeyOutlined />} placeholder="Verification Code" />
                                                </Form.Item>
                                                <Form.Item name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your password!',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                let error;
                                                                let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                                                if (getFieldValue('password').length < 8) {
                                                                    error = 'Password minimum 8 character';
                                                                } else if (regex.exec(getFieldValue('password')) == null) {
                                                                    error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                                                } else {
                                                                    return Promise.resolve();
                                                                }

                                                                return Promise.reject(new Error(error))
                                                            },
                                                        }),
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                                                </Form.Item>

                                                <Form.Item name="confirm_password" dependencies={['password']}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please confirm your password!',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('password') === value) {
                                                                    return Promise.resolve();
                                                                }

                                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" block> Submit </Button>
                                                </Form.Item>

                                            </Form>
                                        </div>
                                }
                            </Card>
                        </Col>
                        <Col xs={0} sm={16}>
                            <img alt="HRIS" style={{ width: '100%', height: '100%' }} src={process.env.PUBLIC_URL + '/images/login-image.svg'} />
                        </Col>
                    </Row>
                    <div className="full-width text-center">
                        © Copyright {new Date().getFullYear()} HRIS. All Rights Reserved.
                    </div>
                </Card>
            </Col>
        </Row>
    );
}

export default Forgot;
