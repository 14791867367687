import { useEffect, useState } from 'react';
import { Layout, Affix, Avatar, Menu } from 'antd';
import { Link } from 'react-router-dom'
import { managementMenus, submissionMenus, payrollMenus, settingMenus, organizationMenus, dashboardMenus, reportMenus, generalAffairMenus, dashboardHrMenus, masterMenus } from './Header/MenuArray'
import Icon from '../components/Icon'
import authData from '../helpers/authData'
import { decrypt } from '../helpers/crypto'
import currentUser from '../helpers/authData';

const { Sider } = Layout;
const { SubMenu } = Menu

const SideBar = (props) => {
    const [collapsed, setCollapsed] = useState(true)
    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    const getMenu = (menu, title, to) => {
        return (
            (!collapsed) ?
                <Link to={to} key={to}>
                    <Icon name={menu} /> {title}
                </Link>
                :
                <Link to={to} key={to}><Icon name={menu} /></Link>
        )
    }
    const checkRoles = (access) => {

        if (access.includes("USER")) return true
        if (role.includes("SUPERADMIN")) return true
        return role.some(function (el) {
            return access.includes(el)
        })
    }

    const isSelectedMenu = (menu) => {
        let x = sessionStorage.getItem('selectedMenu')
        if (x) {
            return (x === menu) ? 'ant-menu-submenu-active' : ''
        } else {
            return ''
        }
    }

    const generateMenu = (menu, name) => {
        for (var key in menu) {
            if (menu[key].name === 'Exit Clearance' && (authData ? authData.employee_status : '') !== 'ESRS') {
                menu.splice(menu[key], 1);
            } else if (menu[key].name === 'Extension' && !role.includes('SUPERADMIN') && !role.includes('INFRA')) {
                menu.splice(key, 1);
            } else if (menu[key].name === 'Employee') {
                for (var index in menu[key].items) {
                    if (menu[key].items[index].name === 'Medical Allowance' && authData.employee_code !== 'SMI20100628033' && authData.employee_code !== 'SMI20220323229') {
                        menu[key].items.splice(index, 1);
                    }
                }
            } else if (menu[key].name === 'KPI' && (authData?.employee_code ?? '') !== 'SMI20200622202' && (authData?.employee_code ?? '') !== 'SMI20220323229' && (authData?.employee_code ?? '') !== 'SMI20100628033') {
                menu.splice(key, 1);
            } else if (menu[key].name === 'Overtime' && (authData?.employee_grade ?? '') >= '3A') {
                menu.splice(key, 1);
            }
        }

        return (
            menu.map((el, i) => (
                (checkRoles(el.role) || (el.key === 'report-menu-daily-activity' && (authData.employee_code === 'SMI20150601108' || authData.employee_code === 'ADM001'))) ? (
                    el.items ?
                        <SubMenu className="sider-submenu" title={el.name} icon={el.icon} key={name + '_menu_' + i}>
                            {
                                el.items.map((submenu, index) => (
                                    (checkRoles(submenu.role)) ?
                                        <Menu.Item className="sider-submenu" key={name + '_submenu_' + index + el.key}>
                                            <Link to={submenu.to} key={name + 'sub_menu_' + index + el.key} component={submenu.component}>{submenu.icon} {submenu.name}</Link>
                                        </Menu.Item>
                                        : null
                                ))
                            }
                        </SubMenu>
                        :
                        <Menu.Item className="sider-submenu" key={name + '_menu_' + i + el.key}>
                            <Link to={el.to} component={el.component}>{el.icon} {el.name}</Link>
                        </Menu.Item>
                ) : null

            ))
        )
    }

    const collapseMenu = (v) => {
        if (props.display === 'auto') {
            localStorage.setItem('menu-collapsed', JSON.stringify(v))
            setCollapsed(v)
        }
    }

    useEffect(() => {
        if (props.display === 'collapsed' || props.display === 'auto') {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }, [props])

    return (
        <div>
            <Affix offsetTop={0}>
                <Sider
                    className="sider-shadow scrollbar-custom"
                    theme="dark"
                    onMouseEnter={() => collapseMenu(false)}
                    onMouseLeave={() => collapseMenu(true)}
                    collapsed={props.display === 'collapsed' || (props.display === 'auto' && collapsed)}
                    trigger={null}
                    collapsible
                    width="200px"
                    style={{ padding: '0px', height: '100vh', overflow: 'auto', paddingTop: '15px', paddingLeft: '0px', textAlign: 'center', backgroundImage: 'linear-gradient(180deg, #0698ae, #3995eb)' }}
                >
                    <Link to="/">
                        {
                            (collapsed) ?
                                <Avatar shape="square" src={process.env.PUBLIC_URL + '/logo-white.png'} style={{ height: 40, width: 40, borderRadius: '5px' }} />
                                :
                                <img alt="logo-karajo" src={process.env.PUBLIC_URL + '/logo-white.png'} style={{ width: 180 }} />
                        }
                    </Link>
                    <Menu theme="dark" mode="inline" style={{ minHeight: '85vh', paddingTop: '10px', backgroundImage: 'linear-gradient(180deg, #0698af, #3495e5)' }}>
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="home" />} key="home" title={(!collapsed) ? " Dashboard" : null}>
                                    {generateMenu(dashboardMenus, 'dashboard')}
                                </SubMenu> :
                                <Menu.Item key="home" title="Dashboard">
                                    {getMenu("dashboard", "Dashboard", "/")}
                                </Menu.Item>
                        }

                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="home" />} key="dashboardHr" title={(!collapsed) ? " Dashboard HR" : null}>
                                    {generateMenu(dashboardHrMenus, 'dashboardHr')}
                                </SubMenu>
                                : null
                        }

                        {
                            (authData ? authData.approval : '') ?
                                <Menu.Item key="approvalCenter" className={isSelectedMenu('approval')} title="Approval Center">
                                    {getMenu("approvalCenter", "Approval Center", "/submission-list")}
                                </Menu.Item>
                                : null
                        }

                        <SubMenu icon={<Icon name="submission" />} key="submission" title={(!collapsed) ? " Submission" : null}>
                            {generateMenu(submissionMenus, 'submission')}
                        </SubMenu>
                        {/* <SubMenu icon={<Icon name="document" />} key="document" title={(!collapsed) ? " Document" : null}>
                            {generateMenu(documentationMenus, 'document')}
                        </SubMenu> */}
                        {
                            role.includes("SUPERADMIN") || role.includes("HEAD") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="management" />} key="management" title={(!collapsed) ? " Management" : null}>
                                    {generateMenu(managementMenus, 'management')}
                                </SubMenu>
                                : null
                        }
                        <SubMenu icon={<Icon name="payroll" />} key="payroll" title={(!collapsed) ? " Payroll" : null}>
                            {generateMenu(payrollMenus, 'payroll')}
                        </SubMenu>
                        <SubMenu icon={<Icon name="users" />} key="organization" title={(!collapsed) ? " Organization" : null}>
                            {generateMenu(organizationMenus, 'organization')}
                        </SubMenu>
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="setting" />} key="setting" title={(!collapsed) ? " Setting" : null}>
                                    {generateMenu(settingMenus, 'setting')}
                                </SubMenu>
                                : null
                        }
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="management" />} key="master" title={(!collapsed) ? " Master" : null}>
                                    {generateMenu(masterMenus, 'master')}
                                </SubMenu>
                                : null
                        }
                        {
                            role.includes("GA") || role.includes("SUPERADMIN") ?
                                <SubMenu icon={<Icon name="general-affair" />} key="general-affair" title={(!collapsed) ? " General Affair" : null}>
                                    {generateMenu(generalAffairMenus, 'general-affair')}
                                </SubMenu>
                                : null
                        }
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") || role.includes("GA") || role.includes("ACC") || role.includes("OPR") || (currentUser && currentUser?.employee_code === 'SMI20131021081') ?
                                <SubMenu icon={<Icon name="report" />} key="report" title={(!collapsed) ? " Report" : null}>
                                    {generateMenu(reportMenus, 'report')}
                                </SubMenu>
                                : null
                        }
                    </Menu>
                </Sider>
            </Affix>
        </div>
    );
}

export default SideBar;
