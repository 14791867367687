import { useState, useEffect } from 'react';
import { Affix, Card, Avatar, Typography, Row, Col, Divider } from 'antd';
import { SentralModel } from '../../models/SentralModel'
import authData from '../../helpers/authData'
import Information from './Information'
// import Leave from './Leave'
// import Probation from './Probation';
// import { decrypt } from '../../helpers/crypto';

const { Title } = Typography

const ProfileImage = (props) => (
    <Col className="text-center">
        <div className="text-center">
            {
                (authData.profile_image) ?
                    <Avatar size={80} src={process.env.REACT_APP_API_FILE + '/' + authData.profile_image.photo} />
                    :
                    <Avatar size={80} src={process.env.PUBLIC_URL + '/images/user-male.png'} />
            }
        </div>
    </Col>
)

const ProfileBox = () => {
    const [user, setUser] = useState(null)
    const [leaves, setLeaves] = useState(null)
    const [attendance, setAttendance] = useState(0)

    // const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    const fetchLeave = () => {
        SentralModel.action('Submission', 'getAnnualLeave', null, null).then(res => {
            setLeaves(res.data)
        })
    }

    const fetchAttendance = () => {
        SentralModel.action('Attendance', 'getMonthlyAttendance', { attendance_id: authData.attendance_id })
            .then((res) => {
                setAttendance(res.data.length)
            }).catch((err) => {
                setAttendance(0)
            })
    }

    useEffect(() => {
        setUser(authData)
        fetchLeave()
        fetchAttendance()

    }, []);

    return (
        <div>
            <Card style={{ width: '100%', minHeight: 300, maxHeight: 500, marginBottom: 10 }} className="box-shadow-2">
                <div className="px-4 pt-4">
                    <Row type="flex" align="top" justify="space-between">
                        <ProfileImage />
                        {user ?
                            <Col>
                                <h5 className="m-0">
                                    <b>{user.name}</b>
                                </h5>
                                <p className="mb-0">{user.division ? user.division.division_name : '-'}</p>
                                <a
                                    href="!#"
                                    css={`
                                    display: block;
                                    `}
                                >
                                    {user.work_email}
                                </a>
                                <br />
                                <a
                                    href="!#"
                                    css={`
                                    display: block;
                                    `}
                                >
                                    {user.mobile_phone}
                                </a>
                            </Col>
                            : null}
                    </Row>
                </div>

                <Divider orientation="center">
                    <small>Stats</small>
                </Divider>
                {user ?
                    <>
                        <Row
                            className="text-center w-100 px-4"
                            type="flex"
                            align="middle"
                            justify="space-between"
                        >
                            <Col span={11} style={{ marginRight: '10px' }}>
                                <h2 className="m-0">
                                    <b>{leaves ? (leaves.balance - leaves.public_mass) : 0}</b>
                                </h2>
                                <small>Leave Balance</small>
                            </Col>
                            <Col span={11} style={{ marginLeft: '10px' }}>
                                <h2 className="m-0">
                                    <b>{attendance}</b>
                                </h2>
                                <small>Attendance</small>
                            </Col>
                        </Row>
                    </>
                    : null}
            </Card>
            {/* {
                role.includes('HR') || role.includes('SUPERADMIN') ?
                    <>
                        <br />
                        <Affix offsetTop={10}>
                            <Card className="box-shadow-2">
                                <Title level={3}>Employee Probation</Title>
                                <Probation />
                            </Card>
                        </Affix>
                    </> : null

            }
            <br />
            <Affix offsetTop={10}>
                <Card className="box-shadow-2">
                    <Title level={3}>Today Leaves</Title>
                    <Leave />
                </Card>
            </Affix>
            <br /> */}
            <Affix offsetTop={10}>
                <Card className="box-shadow-2">
                    <Title level={3}>Announcement</Title>
                    <Information />
                </Card>
            </Affix>
        </div>
    );
}

export default ProfileBox;
