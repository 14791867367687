import { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Form, Input, Select, Button, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { AuthModel } from '../../models/AuthModel'
import { Link } from 'react-router-dom';
import { Spinner, BasicModal } from '../../components';
import { CustomModel } from '../../models/CustomModel';
import { getQuery } from '../../helpers/url'

const { Option } = Select;

const Login = () => {
    const [loaded, setLoaded] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [version, setVersion] = useState({});
    const [showModal, setShowModal] = useState(false)
    const page = useRef('');

    const fetchEmployees = () => {
        setLoaded(false)
        CustomModel.employeeList().then(res => {
            setLoaded(true)
            setEmployees(res.data)
        })
    }

    const fetchVersion = () => {
        setLoaded(false)
        CustomModel.version().then(res => {
            setLoaded(true)
            setVersion(res.data)
        })
    }

    useEffect(() => {
        page.current = getQuery('page')
        fetchEmployees();
        fetchVersion();
    }, []);

    const changeEmployee = e => {
        setSelectedEmployee(e)
    }

    const showVersionDetail = () => {
        setShowModal(true)
    }

    const onFinish = (v) => {
        message.loading('authenticating', 0)
        if (page.current !== 'dev') {
            AuthModel.auth(v.email, v.password).then().catch(() => {
                message.destroy()
                message.error('invalid credentials', 2)
            })
        } else {
            AuthModel.auth(selectedEmployee, 'sc72800110id').then().catch(() => {
                message.destroy()
                message.error('invalid credentials', 2)
            })
        }
    }

    return (
        <div>
            <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col span={18}>
                    <Card style={{ minHeight: 450, borderRadius: 10 }} className="box-shadow">
                        <Row type="flex" style={{ height: 450 }} align="middle">
                            <Col xs={24} sm={8}>
                                <Card style={{ borderRadius: 10 }}>
                                    <div className="full-width text-center" style={{ marginBottom: 30 }}>
                                        <img alt="hris-logo" src={process.env.PUBLIC_URL + '/logo-vertical.png'} style={{ width: 130 }} />
                                    </div>
                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        size="large"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}>
                                        {
                                            page.current !== 'dev' ?
                                                <>
                                                    <Form.Item name="email">
                                                        <Input prefix={<UserOutlined />} placeholder="Email" />
                                                    </Form.Item>
                                                    <Form.Item name="password"  >
                                                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                                                    </Form.Item>
                                                    <Form.Item style={{ margin: 0 }}>
                                                        <Link to={"/forgot-password"}>
                                                            <span style={{ float: 'right' }}>
                                                                Forgot password ?
                                                            </span>
                                                        </Link>
                                                    </Form.Item>
                                                </> :
                                                loaded ?
                                                    <Form.Item name="employee">
                                                        <Select showSearch allowClear placeholder="Select employee" optionFilterProp="children" style={{ width: "100%" }} onChange={changeEmployee} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {
                                                                employees.map((el, key) => (
                                                                    <Option key={key} value={el.work_email}>{el.name}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                    : <Spinner />
                                        }
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" block> Log in </Button>
                                        </Form.Item>
                                    </Form>
                                    <Row>
                                        <Col span={24} className="text-center">
                                            <Link onClick={showVersionDetail}>
                                                {version.version}
                                            </Link>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={0} sm={16}>
                                <img alt="HRIS" style={{ width: '100%', height: '100%', padding: 10 }} src={process.env.PUBLIC_URL + '/images/karajo-login.png'} />
                            </Col>
                        </Row>
                        <div className="full-width text-center">
                            © Copyright {new Date().getFullYear()} HRIS. All Rights Reserved.
                        </div>
                    </Card>
                </Col>
            </Row>

            <BasicModal title={"Version : " + version.version} width={800} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                <p className="mt-3" dangerouslySetInnerHTML={{ __html: version.description }}></p>
            </BasicModal>
        </div>
    );
}

export default Login;
